import React, { useState, useEffect } from "react";
import "./claimPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Swal from "sweetalert2";

const ClaimPage = () => {
  const navigate = useNavigate();
  const [telError, setTelError] = useState(false);
  const [info, setInfo] = useState({
    shippingName: "",
    email: "",
    tel: "",
    address: "",
  });
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);

  const handleOptionChange = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const [user, setUser] = useState({
    userName: "",
    email: "",
    tel: "",
    prize: [],
  });
  useEffect(() => {
    getMe().then((res) => {
      if (res) {
        setUser(res?.data);
      }
    });
  }, []);

  const getMe = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/me`,

        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const validateTel = () => {
    const { tel } = info;
    if (!/^(\+\d{1,3})?\d{10}$/.test(tel)) {
      setTelError(true);
    } else {
      setTelError(false);
    }
  };

  const validateItem = () => {
    if (selectedOptions.length == 0) {
      setError("Please select your prize");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    validateItem();
  }, [selectedOptions]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleClaim = async (e) => {
    e.preventDefault();
    const payload = {
      ...info,
      items: selectedOptions.map((item) => user.prize[item]),
    };
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/payment/claim`, payload)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.success,
        });
        navigate(`/`);
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
        });
      });
  };

  return (
    <>
      <div className="my-container">
        <div className="my-card">
          <h2>Shipping Info</h2>

          <Modal
            className="modal"
            isOpen={isModalOpen}
            onAfterOpen={() => setIsModalOpen(true)}
            onRequestClose={() => setIsModalOpen(false)}
            // style={customStyles}
            contentLabel="Example Modal"
          >
            <h3>Select Your Prize</h3>
            <div className="options-container">
              {user && user.prize?.length === 0 ? (
                <p>Empty</p>
              ) : (
                user.prize.map((option, i) => (
                  <div className="options-panel">
                    <label key={i}>
                      <input
                        type="checkbox"
                        value={option}
                        checked={selectedOptions.includes(i)}
                        onChange={() => handleOptionChange(i)}
                      />
                      {option.split(" ").slice(0, -1).join(" ") +
                        " (" +
                        new Date(Number(option.split(" ").slice(-1))).getHours() +
                        ":" +
                        new Date(
                          Number(option.split(" ").slice(-1))
                        ).getMinutes() +
                        ":" +
                        new Date(
                          Number(option.split(" ").slice(-1))
                        ).getSeconds() +
                        ", " +
                        new Date(
                          Number(option.split(" ").slice(-1))
                        ).toDateString() +
                        ")"}
                    </label>
                  </div>
                ))
              )}
            </div>
            

            <div className="btn-panel">
              <button
                type="button"
                className="close-btn"
                onClick={() => setIsModalOpen(false)}
              >
                Close
              </button>
            </div>
          </Modal>

          <Modal
            className="modal"
            isOpen={isModalConfirmOpen}
            onAfterOpen={() => setIsModalConfirmOpen(true)}
            onRequestClose={() => setIsModalConfirmOpen(false)}
            // style={customStyles}
            contentLabel="Example Modal"
          >
            <div style={{textAlign:"center"}}>
              <h1>Summary</h1>
              <h3 style={{textAlign: "left"}}>Shipping Info</h3>
              <p className="info">shipping name: {info.shippingName}</p>
              <p className="info">email: {info.email}</p>
              <p className="info">tel: {info.tel}</p>
              <p className="info">address: {info.address}</p>
              <h3 style={{textAlign: "left"}}>Selected Prize</h3>
              {selectedOptions.map((item) => (
                <li className="info">
                  {user.prize[item].split(" ").slice(0, -1).join(" ") +
                    " (" +
                    new Date(
                      Number(user.prize[item].split(" ").slice(-1))
                    ).getHours() +
                    ":" +
                    new Date(
                      Number(user.prize[item].split(" ").slice(-1))
                    ).getMinutes() +
                    ":" +
                    new Date(
                      Number(user.prize[item].split(" ").slice(-1))
                    ).getSeconds() +
                    ", " +
                    new Date(
                      Number(user.prize[item].split(" ").slice(-1))
                    ).toDateString() +
                    ")"}
                </li>
              ))}
              <br/>
              <p className="warn">*หากสินค้าน้อยกว่า 3 ชิ้นจะมีค่าส่ง 50 Coins</p>
              <div className="btn-panel">
                <button
                  type="button"
                  className="close-btn"
                  onClick={() => setIsModalConfirmOpen(false)}
                >
                  Close
                </button>
                <button type="button" className="close-btn" onClick={handleClaim}>
                  Confirm
                </button>
              </div>
            </div>
            
          </Modal>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsModalConfirmOpen(true);
            }}
          >
            <input
              type="text"
              name="shippingName"
              placeholder="Shipping name (Full name)"
              required
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              onChange={handleInputChange}
            />
            <input
              type="tel"
              name="tel"
              placeholder="Telephone"
              required
              onChange={handleInputChange}
              onBlur={validateTel}
            />
            {telError && <p className="error">Invalid telephone number.</p>}
            <input
              type="text"
              name="address"
              placeholder="Address"
              required
              onChange={handleInputChange}
            />

            <div className="multi-choice">
              <button
                style={{ marginBottom: "10px", userSelect: "none" }}
                type="button"
                className="toggle-btn"
                onClick={() => setIsModalOpen(true)}
              >
                Select your prize
              </button>
            </div>

            <button
              style={{ userSelect: "none" }}
              type="submit"
              disabled={telError || selectedOptions.length == 0}
            >
              Claim
            </button>
            {error && <p className="error">{error}</p>}
            {msg && <p className="msg">{msg}</p>}
          </form>
        </div>
      </div>
    </>
  );
};

export default ClaimPage;
