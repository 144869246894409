import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.css";
import Modal from "react-modal";
import { useState } from "react";

const Navbar = ({
  isLoggedIn,
  isAdmin,
  userName,
  coin,
  isTopUpModalOpen,
  setIsTopUpModalOpen,
}) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };
  const [topUpPrice, setTopUpPrice] = useState(0);

  const handleMakePay = async () => {
    if ([50, 100, 300, 500, 1000].includes(topUpPrice)) {
      navigate(`/payment/${topUpPrice}`);
      setIsTopUpModalOpen(false);
      window.location.reload();
    }
  };
  return (
    <nav className="navbar">
      <Modal
        className="modal-nav"
        isOpen={isTopUpModalOpen}
        onAfterOpen={() => setIsTopUpModalOpen(true)}
        onRequestClose={() => setIsTopUpModalOpen(false)}
        contentLabel="Example Modal"
      >
        <h2>Selected Price : {topUpPrice}</h2>
        <div className="select-price-container">
          <button
            type="button"
            className={`price-btn ${topUpPrice === 50 ? "selected" : ""}`}
            onClick={() => setTopUpPrice(50)}
          >
            50
          </button>
          <button
            type="button"
            className={`price-btn ${topUpPrice === 100 ? "selected" : ""}`}
            onClick={() => setTopUpPrice(100)}
          >
            100
          </button>
          <button
            type="button"
            className={`price-btn ${topUpPrice === 300 ? "selected" : ""}`}
            onClick={() => setTopUpPrice(300)}
          >
            300
          </button>
          <button
            type="button"
            className={`price-btn ${topUpPrice === 500 ? "selected" : ""}`}
            onClick={() => setTopUpPrice(500)}
          >
            500
          </button>
          <button
            type="button"
            className={`price-btn ${topUpPrice === 1000 ? "selected" : ""}`}
            onClick={() => setTopUpPrice(1000)}
          >
            1000
          </button>
        </div>
        <div className="btn-panel">
          <button
            type="button"
            className="close-btn"
            onClick={() => setIsTopUpModalOpen(false)}
          >
            Close
          </button>
          <button type="button" className="close-btn" onClick={handleMakePay}>
            Confirm
          </button>
        </div>
      </Modal>
      <ul className="nav-menu">
        <li className="nav-item">
          <Link to="/" className="nav-item-logo">
            KEEB
          </Link>
        </li>
        {isLoggedIn ? (
          <li className="nav-item">
            <Link to="/claim" className="nav-link">
              Claim Reward
            </Link>
          </li>
        ) : null}
      </ul>

      {isLoggedIn ? (
        <ul className="nav-menu">
          <div
            style={{
              display: "flex",
              margin: "0px",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <li className="nav-item nav-status">Name: {userName}</li>
            <li className="nav-item nav-status">Coin: {coin}</li>
          </div>
          {isAdmin ? <>
            <li className="nav-item">
              <Link to="/vendor" className="nav-link">
                asVendor
              </Link>
            </li>
          </> : null}
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={() => {
                setIsTopUpModalOpen(true);
              }}
            >
              Top-Up
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={handleLogout}>
              Logout
            </Link>
          </li>
        </ul>
      ) : (
        <ul className="nav-menu">
          <li className="nav-item">
            <Link to="/register" className="nav-link nav-link-accent">
              Register
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/login" className="nav-link nav-link-accent">
              Login
            </Link>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
