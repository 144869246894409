import { useParams } from "react-router-dom";
import io from "socket.io-client";

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import "./videoReceiver.css";

const LoadingSpinner = () => (
  <TailSpin
    color="white" // Customize the color
    height={50} // Adjust the height of the spinner
    width={50} // Adjust the width of the spinner
  />
);

const VideoContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`;

const Receiver = ({ machineID, camNumber }) => {
  const partnerVideo = useRef();
  const peerRef = useRef();
  const socketRef = useRef();
  const otherUser = useRef();
  const userStream = useRef();

  const [myID, setMyID] = useState();
  const [stream1, setStream1] = useState();
  const [stream2, setStream2] = useState();
  const [camID1, setCamID1] = useState();
  const [camID2, setCamID2] = useState();
  const peersRef = useRef([]);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((stream) => {
        // userVideo.current.srcObject = stream;
        userStream.current = stream;

        if (!socketRef.current) {
          socketRef.current = io.connect(process.env.REACT_APP_API_BASE_URL);
          // console.log(roomID);
          socketRef.current.emit("INIT_USER", {
            machineID: machineID,
            token: localStorage.getItem("token"),
          });
          socketRef.current.on("id_report", (id) => {
            setMyID(id);
          });

          socketRef.current.on("other user", (userID) => {
            console.log(userID);
            otherUser.current = userID;
          });

          socketRef.current.on("user joined", (userID) => {
            console.log(userID);
            callUser(userID);
            otherUser.current = userID;
          });

          socketRef.current.on("offer", handleRecieveCall);

          socketRef.current.on("answer", handleAnswer);

          socketRef.current.on("ice-candidate", handleNewICECandidateMsg);
        }
      });
  }, []);

  //   useEffect(() => {
  //     if (camID1) {
  //       console.log("call1");
  //       console.log(camID1);
  //       callUser(camID1);
  //     }
  //     if (camID2) {
  //       console.log("call2");
  //       callUser(camID2);
  //     }
  //   }, [camID1, camID2]);

  function callUser(userID) {
    peerRef.current = createPeer(userID);
    userStream.current.getTracks().forEach((track) => {
      peerRef.current.addTrack(track, userStream.current);
    });
  }

  function createPeer(userID) {
    const peer = new RTCPeerConnection({
      iceServers: [
        {
          urls: process.env.REACT_APP_STUN_SERVER,
        },
        {
          urls: process.env.REACT_APP_TURN_SERVER,
          username: process.env.REACT_APP_TURN_USER,
          credential: process.env.REACT_APP_TURN_PASSWORD,
        },
      ],
    });

    peer.onicecandidate = handleICECandidateEvent;
    peer.ontrack = (e) => {
      if (!stream1) {
        setStream1(e.streams[0]);
      } else {
        setStream2(e.streams[0]);
      }
    };
    peer.onnegotiationneeded = () => handleNegotiationNeededEvent(userID);

    return peer;
  }

  function handleNegotiationNeededEvent(userID) {
    peerRef.current
      .createOffer()
      .then((offer) => {
        return peerRef.current.setLocalDescription(offer);
      })
      .then(() => {
        const payload = {
          target: userID,
          caller: socketRef.current.id,
          sdp: peerRef.current.localDescription,
        };
        console.log(payload);
        socketRef.current.emit("offer", payload);
      })
      .catch((e) => console.log(e));
  }

  function handleRecieveCall(incoming) {
    peerRef.current = createPeer();
    const desc = new RTCSessionDescription(incoming.sdp);
    peerRef.current
      .setRemoteDescription(desc)
      .then(() => {
        // userStream.current
        //   .getTracks()
        //   .forEach((track) =>
        //     peerRef.current.addTrack(track, userStream.current)
        //   );
      })
      .then(() => {
        return peerRef.current.createAnswer();
      })
      .then((answer) => {
        return peerRef.current.setLocalDescription(answer);
      })
      .then(() => {
        const payload = {
          target: incoming.caller,
          caller: socketRef.current.id,
          sdp: peerRef.current.localDescription,
        };
        socketRef.current.emit("answer", payload);
      });
  }

  function handleAnswer(message) {
    const desc = new RTCSessionDescription(message.sdp);
    peerRef.current.setRemoteDescription(desc).catch((e) => console.log(e));
  }

  function handleICECandidateEvent(e) {
    if (e.candidate) {
      const payload = {
        target: otherUser.current,
        candidate: e.candidate,
      };
      socketRef.current.emit("ice-candidate", payload);
    }
  }

  function handleNewICECandidateMsg(incoming) {
    const candidate = new RTCIceCandidate(incoming);

    peerRef.current.addIceCandidate(candidate).catch((e) => console.log(e));
  }

  //   function handleTrackEvent(e) {
  //     // setStream1(e.streams[0]);
  //     // console.log(e);
  //     setStream1(e.streams[0]);
  //   }

  useEffect(() => {
    // Update the video elements when stream1 or stream2 changes
    if (stream1 && camNumber === 0) {
      const videoRef = document.getElementById("video1"); // Get the video element by ID
      if (videoRef) {
        videoRef.srcObject = stream1;
        // Check if the video is ready to show
        videoRef.onloadeddata = () => {
          setIsLoading1(false);
        };
      }
    }
    if (stream2 && camNumber === 1) {
      const videoRef = document.getElementById("video2"); // Get the video element by ID
      if (videoRef) {
        videoRef.srcObject = stream2;
        videoRef.onloadeddata = () => {
          setIsLoading2(false);
        };
      }
    }
  }, [stream1, stream2, camNumber]);

  return (
    <VideoContainer>
      {camNumber === 0 ? (
        stream1 ? (
          <div>
            {isLoading1 ? (
              <div className="BlankContainer">
                <LoadingSpinner />
              </div>
            ) : null}
            <video
              className={`video-machine ${isLoading1 ? "hidden" : ""}`}
              id="video1"
              playsInline
              autoPlay
              muted
            />
          </div>
        ) : (
          <div className="BlankContainer">
            <LoadingSpinner />
          </div>
        )
      ) : null}
      {camNumber === 1 ? (
        stream2 ? (
          <>
            {isLoading2 ? (
              <div className="BlankContainer">
                <LoadingSpinner />
              </div>
            ) : null}
            <video
              className={`video-machine ${isLoading2 ? "hidden" : ""}`}
              id="video2"
              playsInline
              autoPlay
              muted
            />
          </>
        ) : (
          <div className="BlankContainer">
            <LoadingSpinner />
          </div>
        )
      ) : null}
    </VideoContainer>
  );
};

export default Receiver;
