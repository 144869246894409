import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div>
      <h1>Oops! You seem to be lost.</h1>
      <h3>Here are some helpful links:</h3>
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Link to="/">Home</Link>
        <Link to="/login">Login</Link>
        <Link to="/register">Register</Link>
      </div>
    </div>
  );
}
