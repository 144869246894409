import React, { useState } from "react";
import "../../adminPage/adminPage.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useEffect } from "react";
import AdminPage from "../../adminPage/adminPage";
import axios from "axios";

const CameraSetting = () => {
  const navigate = useNavigate();
  const [machineID, setMachineID] = useState("64ba69b55dc4553eb3684380");
  const [camNumber, setCamNumber] = useState(1);
  const [camSource, setCamSource] = useState(0);
  const [camOption, setCamOption] = useState([]);
  const [machines, setMachines] = useState([])

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }

        console.log("request to access camera");
      });

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      // console.log(videoDevices);
      if (videoDevices.length === 0) {
        console.error("No video input devices found");
        return;
      }
      // console.log(videoDevices.map((x) => x.label));
      setCamOption(videoDevices.map((x, i) => ({ value: i, label: x.label })));
    });
    fetchMachines();
  }, []);

  const fetchMachines = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/all`,

        {
          withCredentials: true,
        }
      );
      const resMachine = response.data.data.reduce(
        (accumulator, currentValue) => 
          accumulator.concat({ value: currentValue._id, label: currentValue.machineName }),
        []
      ) 
      setMachines(resMachine);
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`sender/${machineID}/${camNumber}/${camSource}`);
  };

  const handleCamNumberChange = (data) => {
    setCamNumber(data?.value);
  };

  const handleCamSourceChange = (data) => {
    setCamSource(data?.value);
  };
  return (
    <div className="container-machine">
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: "#333",
          padding: "30px",
          borderRadius: "3%",
        }}
      >
        <div style={{ width: "400px" }}>
          <form onSubmit={handleSubmit}>
            <h2 style={{ color: "#f1c40f", margin: "0" }}>Camera Setting</h2>
            <label>
              MachineID :
              <Select
                onChange={(event)=>setMachineID(event?.value)}
                style={{ color: "black" }}
                options={machines}
                defaultValue={machines?.[0]}
              />
            </label>
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              Cam Number :
              <Select
                onChange={handleCamNumberChange}
                style={{ color: "black" }}
                options={[
                  { value: 1, label: "1" },
                  { value: 2, label: "2" },
                ]}
                defaultValue={{ value: 1, label: "1" }}
              />
            </label>
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              Cam Source :
              {camOption[0] ? (
                <Select
                  onChange={handleCamSourceChange}
                  defaultValue={camOption[0]}
                  style={{ color: "black" }}
                  options={camOption}
                />
              ) : null}
            </label>

            <input
              className="nav-link"
              type="submit"
              value="Open Camera"
              style={{ margin: "10px" }}
            />
          </form>
        </div>

        {/* <Link to="serder">Open Camera</Link> */}
      </div>
    </div>
  );
};
export default CameraSetting;
