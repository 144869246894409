import React from "react";
import "./footer.css";
import logoImage from "../../resorce/KEEB.png";
import { AiFillFacebook } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import LineLogo from "../../resorce/LINE_Brand_icon.png";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-logo">
          <img src={logoImage} alt="Logo" />
          <h3>KEEB</h3>
        </div>
        <div className="footer-content">
          <div className="footer-links">
            <ul>
              <li>
                <a
                  href="https://web.facebook.com/profile.php?id=61550477137057"
                  target="_blank"
                >
                  <AiFillFacebook /> Facebook
                </a>
              </li>

              <li>
                <a href="https://lin.ee/PoD4YUN" target="_blank">
                  <img src={LineLogo} style={{ width: "16px" }}></img> Line
                </a>
              </li>
            </ul>
            <ul>
              <a>
                <MdEmail /> Email: keeb.claw.game@gmail.com
              </a>
            </ul>
          </div>
          <div className="footer-copyright">
            <p>
              &copy; {new Date().getFullYear()} KEEB Claw Machine Game. All
              Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
