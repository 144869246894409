import React, { useState, useEffect } from "react";
import "./machinesPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Slideshow from "../components/slideImage/slideImage";

const MachinesPage = () => {
  const navigate = useNavigate();
  const [machines, setMachines] = useState([]);
  const [typesWithLength, setTypesWithLength] = useState();
  const token = localStorage.getItem("token");
  const showItemLimit = 7;
  useEffect(() => {
    getmachines().then((res) => {
      if (res) {
        const classify_machine = {};
        res?.data.forEach((machine) => {
          if (machine.isEnable){
            const type = machine.name.trim().split(" ")[0];
            if (classify_machine[type]) {
              classify_machine[type].push(machine);
            } else {
              classify_machine[type] = [machine];
            }
          }
        });
        for (const type in classify_machine) {
          if (
            classify_machine[type].filter((m) => m.price < 1000).length === 0
          ) {
            delete classify_machine[type];
            continue;
          }
          classify_machine[type] = classify_machine[type].sort((a, b) => {
            if (a.price !== b.price) {
              return a.price - b.price;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        }
        let l = Object.keys(classify_machine).map((type) => ({
          isExpand: false,
          type,
          length: classify_machine[type].length,
        }));

        // // Sort the types by the length of machines in descending order
        // l.sort((a, b) => b.length - a.length);
        // console.log(l);

        // Separate the special type
        const specialType = l.find(
          (typeObj) => typeObj.type === "ทดลองระบบคีบฟรี"
        );
        if (specialType) {
          l = l.filter((typeObj) => typeObj.type !== specialType.type);
        }

        // Sort the types (excluding the special type) by the length of machines in descending order
        l.sort((a, b) => b.length - a.length);

        // Prepend the special type to the sorted array
        if (specialType) {
          l.unshift(specialType);
        }
        setTypesWithLength(l);
        setMachines(classify_machine);
      }
    });
  }, []);

  const getmachines = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/prize/user`,

        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const handleClickMachine = (prizeId) => {
    if (!token) {
      navigate("/login");
    } else {
      navigate(`play/${prizeId}`);
    }
  };

  return (
    <div className="container-machine-page">
      <div className="imageBanner">
        <Slideshow />
      </div>
      {typesWithLength ? (
        typesWithLength.map((e) => (
          <div className="container-machine">
            <h4 className="page-title">{e.type}</h4>
            <div className="card-container">
              {!e.isExpand && e.length > showItemLimit ? (
                <>
                  {machines[e.type].slice(0, showItemLimit).map((machine) =>
                    machine.price < 1000 ? (
                      <div
                        className="card-machine"
                        key={machine._id}
                        onClick={() => handleClickMachine(machine._id)}
                      >
                        <img
                          className="card-image"
                          src={machine.image}
                          alt={machine.name}
                        />
                        <div className="card-content">
                          <h3 className="card-title">{machine.name}</h3>
                          <p className="card-description">
                            {machine.description}
                          </p>
                          <p className="card-price">
                            {machine.price} coin/play
                          </p>
                        </div>
                      </div>
                    ) : null
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                    }}
                  >
                    <p
                      onClick={() => {
                        setTypesWithLength((prevState) => {
                          const updatedTypes = prevState.map((item) => {
                            if (item.type === e.type) {
                              return { ...item, isExpand: true };
                            }
                            return item;
                          });
                          return updatedTypes;
                        });
                      }}
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        fontSize: "18px",
                      }}
                    >
                      Show More...
                    </p>
                  </div>
                </>
              ) : (
                machines[e.type].map((machine) =>
                  machine.price < 1000 ? (
                    <div
                      className="card-machine"
                      key={machine._id}
                      onClick={() => handleClickMachine(machine._id)}
                    >
                      <img
                        className="card-image"
                        src={machine.image}
                        alt={machine.name}
                      />
                      <div className="card-content">
                        <h3 className="card-title">{machine.name}</h3>
                        <p className="card-description">
                          {machine.description}
                        </p>
                        <p className="card-price">{machine.price} coin/play</p>
                      </div>
                    </div>
                  ) : null
                )
              )}
              {/* {machines
            .sort((a, b) => {
              if (a.price !== b.price) {
                return a.price - b.price;
              } else {
                // If prices are equal, compare by name
                return a.name.localeCompare(b.name);
              }
            })
            .map((machine) =>
              machine.price < 1000 ? (
                <div
                  className="card-machine"
                  key={machine._id}
                  onClick={() => handleClickMachine(machine._id)}
                >
                  <img
                    className="card-image"
                    src={machine.image}
                    alt={machine.name}
                  />
                  <div className="card-content">
                    <h3 className="card-title">{machine.name}</h3>
                    <p className="card-description">{machine.description}</p>
                    <p className="card-price">{machine.price} coin/play</p>
                  </div>
                </div>
              ) : null
            )} */}
            </div>
          </div>
        ))
      ) : (
        <h2>Loading...</h2>
      )}
    </div>
  );
};

export default MachinesPage;
