import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {FormCheck } from "react-bootstrap";

export default function EditUser({ id }) {
  const navigate = useNavigate();

  const [user, setUser] = useState("");
  const [validationError, setValidationError] = useState({});

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/${id}`)
      .then(({ data }) => {
        setUser(data.data)
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  const updateUser = async (e) => {
    e.preventDefault();

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/${id}`,
        user
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.success,
        });
        navigate("/admin/user");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  return (

      <div className="col-12 col-sm-12 col-md-6">
        <div className="my-card">
          <div className="card-body">
            <h4 className="create-title">Update User</h4>
            <hr />
            <div className="form-wrapper">
              {Object.keys(validationError).length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <ul className="mb-0">
                        {Object.entries(validationError).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <Form onSubmit={updateUser}>
                <Row>
                  <Col>
                    <Form.Group controlId="User Name">
                      <Form.Label>User Name</Form.Label>
                      <br />
                      <Form.Control
                        type="text"
                        value={user.name}
                        onChange={(event) => {
                          setUser({...user, name:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-0">
                  <Col>
                    <Form.Group controlId="Email">
                      <Form.Label>Email</Form.Label>
                      <br />
                      <Form.Control
                        type="text"
                        disabled
                        value={user.email}
                        onChange={(event) => {
                          setUser({...user, email:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-0">
                  <Col>
                    <Form.Group controlId="Tel">
                      <Form.Label>Tel</Form.Label>
                      <br />
                      <Form.Control
                        type="text"
                        value={user.tel}
                        onChange={(event) => {
                          setUser({...user, tel:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="Role" className="mb-0">
                      <Form.Label>role</Form.Label>
                      <br />
                      <Form.Select
                        value={user.role}
                        onChange={(event) => {
                          setUser({...user, role:event.target.value});
                        }}
                      >
                        <option value="user">user</option>
                        <option value="vendor">vendor</option>
                        <option value="admin">admin</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {user.role != "user" ? 
                  <Row>
                    <Col>
                      <Form.Group controlId="Store Name">
                        <Form.Label>Store Name</Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          value={user.storeName}
                          onChange={(event) => {
                            setUser({...user, storeName:event.target.value});
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="Store Name">
                        <Form.Label>Line ID</Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          value={user.lineId}
                          onChange={(event) => {
                            setUser({...user, lineId:event.target.value});
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="custom-switch">
                        <Form.Check
                        label="Online Enable Payment"
                        checked={user.isEnablePayment}
                        onChange={() => {
                          setUser({...user, isEnablePayment:!user.isEnablePayment});
                        }}
                        />
                      </Form.Group>
                    </Col>
                    
                  </Row>: null
                }
                <Row>
                  <Button
                    variant="primary"
                    className="mt-2"
                    size="lg"
                    block="block"
                    type="submit"
                  >
                    Update
                  </Button>
                </Row>
                
              </Form>
            </div>
          </div>
        </div>
      </div>
  );
}
