import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function CreateProduct() {
  const navigate = useNavigate();

  const [newMachine, setNewMachine] = useState("");
  const [validationError, setValidationError] = useState({});
  const [ vendors, setVendors ] = useState([]);

  useEffect(() => {
    getVendor();
  }, []);


  const createProduct = async (e) => {
    e.preventDefault();

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/`, newMachine)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.success,
        });
        navigate("/admin/machine");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  const getVendor = async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/vendors`)
      .then(({ data }) => {
        setVendors(data.data);
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  return (
      <div className="col-12 col-sm-12 col-md-6">
        <div className="my-card">
          <div className="card-body">
            <h4 className="create-title">Create Machine</h4>
            <hr />
            <div className="form-wrapper">
              {Object.keys(validationError).length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <ul className="mb-0">
                        {Object.entries(validationError).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <Form onSubmit={createProduct}>
                <Row>
                  <Col>
                    <Form.Group controlId="machineName">
                      <Form.Label>Machine Name</Form.Label>
                      <br/>
                      <Form.Control
                        type="text"
                        value={newMachine.name}
                        onChange={(event) => {
                          setNewMachine({...newMachine, machineName:event.target.value});
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="belong" className="mb-2">
                      <Form.Label>Belong</Form.Label>
                      <br/>
                      <Form.Select className="input_update"
                        value={newMachine.belong}
                        onChange={(event) => {
                          setNewMachine({...newMachine, belong:event.target.value});
                        }}
                      >
                        <option value={null}>ไม่มีเจ้าของ</option>
                        {vendors.map(vendor=>{
                          return <option value={vendor._id}>{vendor.email}</option>
                        })}
                        
                        
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  className="mt-2"
                  size="lg"
                  block="block"
                  type="submit"
                >
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
  );
}
