import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import RTCMultiConnection from "rtcmulticonnection-react-js";
import io from "socket.io-client";
import styled from "styled-components";
import MRecordRTC from "recordrtc";

const Video = styled.video`
  width: 100%; /* Adjust as needed */
  height: auto;
  margin: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: 85px;
  margin-bottom: 20px;
  background-color: white;
  padding: 10px;
`;

const Status = styled.h5`
  margin: 5px;
`;

const CallerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CallButton = styled.button`
  margin: 1rem;
  padding: 0.8rem;
  border-radius: 2rem;
  border-width: 0rem;
  &:hover {
    background-color: gray;
  }
  &:focus {
    outline: none;
  }
`;

const RecievingCallButton = styled.button`
  margin: 1rem;
  padding: 0.8rem;
  border-radius: 2rem;
  border-width: 0rem;
  color: white;
  background-color: darkolivegreen;
  &:hover {
    background-color: green;
  }
  &:focus {
    outline: none;
  }
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BlankContainer = styled.div`
  background-color: gray;
  height: 20rem;
  width: 28rem;
  margin: 2rem;
`;

const VideoBroadcasting = () => {
  const connection = useRef();
  const { machineID, camNumber, sourceNumber } = useParams();
  const [stream, setStream] = useState();
  const [myID, setMyID] = useState();
  const [onlineUsers, setOnlineUsersList] = useState([]);
  const [deviceName, setDeviceName] = useState("NaN");
  const socket = useRef();
  const userVideo = useRef();
  const [isOnline, setIsOnline] = useState(false);
  const recorder = useRef();
  const [isRecording, setIsRecording] = useState(false);

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .enumerateDevices()
  //     .then((devices) => {
  //       const videoDevices = devices.filter(
  //         (device) => device.kind === "videoinput"
  //       );
  //       // console.log(videoDevices);
  //       if (videoDevices.length === 0) {
  //         console.error("No video input devices found");
  //         return;
  //       }

  //       // Assuming you want to use the first available webcam device.
  //       const selectedDevice = videoDevices[Number(sourceNumber)].deviceId;
  //       // console.log(videoDevices[Number(sourceNumber)].label);
  //       setDeviceName(videoDevices[Number(sourceNumber)].label);

  //       const constraints = {
  //         video: {
  //           deviceId: { exact: selectedDevice },
  //         },
  //         audio: false,
  //       };

  //       return navigator.mediaDevices.getUserMedia(constraints);
  //     })
  //     .then((stream) => {
  //       // Use the selected webcam stream
  //       if (stream) {
  //         setStream(stream);
  //         // if (!socket.current) {
  //         //   socket.current = io.connect(process.env.REACT_APP_API_BASE_URL);
  //         //   socket.current.emit("INIT_CAM", {
  //         //     machineID: machineID,
  //         //     camNumber: Number(camNumber),
  //         //     token: localStorage.getItem("token"),
  //         //   });
  //         //   socket.current.on("online_users_report", (users) => {
  //         //     setOnlineUsersList(users);
  //         //   });
  //         //   socket.current.on("id_report", (id) => {
  //         //     setMyID(id);
  //         //   });
  //         // }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error accessing webcam:", error);
  //     });
  // }, []);

  useEffect(() => {
    let i = 1;
    try {
      if (!connection.current) {
        connection.current = new RTCMultiConnection();

        connection.current.socketURL = process.env.REACT_APP_API_CAM + "/";

        socket.current = io.connect(process.env.REACT_APP_API_CAM + "/");
        socket.current.emit("machine connecting", {
          machineID: machineID,
          camnumber: camNumber,
        });

        connection.current.socketMessageEvent = "video-broadcast-demo";

        connection.current.session = {
          audio: false,
          video: true,
          oneway: true,
        };

        // first step, ignore default STUN+TURN servers
        connection.current.iceServers = [];

        // second step, set STUN url
        connection.current.iceServers.push({
          urls: process.env.REACT_APP_STUN_SERVER,
        });

        // last step, set TURN url (recommended)
        connection.current.iceServers.push({
          urls: process.env.REACT_APP_TURN_SERVER,
          username: process.env.REACT_APP_TURN_USER,
          credential: process.env.REACT_APP_TURN_PASSWORD,
        });

        connection.current.dontCaptureUserMedia = true;

        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            const videoDevices = devices.filter(
              (device) => device.kind === "videoinput"
            );
            // console.log(videoDevices);
            if (videoDevices.length === 0) {
              console.error("No video input devices found");
              return;
            }

            // Assuming you want to use the first available webcam device.
            const selectedDevice = videoDevices[Number(sourceNumber)].deviceId;
            // console.log(videoDevices[Number(sourceNumber)].label);
            setDeviceName(videoDevices[Number(sourceNumber)].label);

            const constraints = {
              video: {
                deviceId: { exact: selectedDevice },
                width: { ideal: 640 },
                height: { ideal: 480 },
              },
              audio: false,
            };

            return navigator.mediaDevices.getUserMedia(constraints);
          })
          .then((stream) => {
            setStream(stream);
            // socket.current.on("start recording", () => {
            //   console.log("start rec");
            //   startRecording(stream);
            // });

            // socket.current.on("stop recording", () => {
            //   console.log("stop rec");
            //   stopRecording();
            // });
            if (userVideo.current) {
              userVideo.current.srcObject = stream;
            }
            connection.current.videosContainer =
              document.getElementById("videos-container");
            connection.current.attachStreams = [stream];
            if (camNumber == 1) {
              showCam1();
            }
            if (camNumber == 2) {
              showCam2();
            }
            setInterval(() => {
              if (camNumber == 1) {
                showCam1();
              }
              if (camNumber == 2) {
                showCam2();
              }
            }, 60000);
          });
        const peerCleanup = () => {
          i++;
          if (!(i % 3)) {
            // Attempt to invoke garbage collection
            queueMicrotask(() => {
              let img = document.createElement("img");
              img.src = window.URL.createObjectURL(new Blob([new ArrayBuffer(5e+7)])); // 50MB buffer
              img.onerror = function () {
                window.URL.revokeObjectURL(this.src);
                img = null;
              };
            });
          }
        };
        const showCam1 = () => {
          connection.current.sdpConstraints.mandatory = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false,
          };
          connection.current.open(`${machineID}1`, function (isRoomOpened) {
            setIsOnline(isRoomOpened);
            console.log(connection.current.sessionid);
            if (!isRoomOpened) {
              window.location.reload();
            }
            peerCleanup();
          });
        };
        const showCam2 = () => {
          connection.current.sdpConstraints.mandatory = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false,
          };
          connection.current.open(`${machineID}2`, function (isRoomOpened) {
            setIsOnline(isRoomOpened);
            console.log(connection.current.sessionid);
            if (!isRoomOpened) {
              window.location.reload();
            }
            peerCleanup();
          });
        };
      }
    }catch (error) {
      handleError(error); // Handle the error if it occurs
    }
  }, []);
  

  const startRecording = (stream) => {
    if (stream) {
      recorder.current = new MRecordRTC(stream, {
        type: "video",
        mimeType: "video/webm",
      });

      recorder.current.startRecording();
      setIsRecording(true);
    } else {
      console.error("Cannot start recording, stream is undefined");
    }
  };
  const stopRecording = () => {
    if (recorder.current) {
      recorder.current.stopRecording(function (url) {
        const videoBlob = recorder.current.getBlob();
        const videoUrl = URL.createObjectURL(videoBlob);

        const videoElement = document.getElementById("saveVDO");

        if (videoElement) {
          videoElement.src = videoUrl;
          videoElement.play();
        } else {
          console.error("Video element not found in the DOM");
        }
        console.log(videoUrl);
        recorder.current.save(); // Save the recorded video
        setIsRecording(false);
      });
    } else {
      console.error("Cannot stop recording, recorder is undefined");
    }
  };

  const handleError = (error) => {
    if (error.message.includes("Cannot create so many PeerConnections")) {
      console.error("Too many PeerConnections. Reloading page...");
      window.location.reload();
    } else {
      console.error("Error occurred:", error);
    }
  };

  return (
    <Container>
      <button
        onClick={isRecording ? stopRecording : () => startRecording(stream)}
      >
        {isRecording ? "Stop Record" : "Start Record"}
      </button>
      <h2>Strat to stream</h2>
      {isOnline ? (
        <Status style={{ color: "green" }}>Status: Online</Status>
      ) : (
        <Status style={{ color: "red" }}>Status: Offline</Status>
      )}

      <Video id="saveVDO"></Video>
      <VideoContainer id="videos-container">
        {/* {stream ? <Video playsInline muted ref={userVideo} autoPlay /> : null} */}
      </VideoContainer>

      <Status>
        machineID: <p>{machineID}</p>
      </Status>
      <Status>
        cam number: <p>{camNumber}</p>
      </Status>
      <Status>
        device number: <p>{deviceName}</p>
      </Status>

      <hr style={{ width: "80%" }} />
      {/* <div style={{ display: "flex", flexDirection: "column" }}>
        <Status>Users:</Status>
        {onlineUsers.map((user) => {
          return <CallButton>{`${user.user.name}`}</CallButton>;
        })}
      </div> */}
    </Container>
  );
};

export default VideoBroadcasting;
