import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import io from "socket.io-client";
import { TailSpin } from "react-loader-spinner";
import "./videoReceiver/videoReceiver.css";
import RTCMultiConnection from "rtcmulticonnection-react-js";

const LoadingSpinner = () => (
  <TailSpin
    color="white" // Customize the color
    height={50} // Adjust the height of the spinner
    width={50} // Adjust the width of the spinner
  />
);

const VideoContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`;

const Listenner = ({ machineID, camNumber }) => {
  const connection = useRef();
  const [myID, setMyID] = useState();
  const socket = useRef();
  const [stream1, setStream1] = useState();
  const [stream2, setStream2] = useState();
  const [camID1, setCamID1] = useState();
  const [camID2, setCamID2] = useState();
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  // useEffect(() => {
  //   if (!socket.current) {
  //     socket.current = io.connect(process.env.REACT_APP_API_BASE_URL);
  //     socket.current.emit("INIT_USER", {
  //       machineID: machineID,
  //       token: localStorage.getItem("token"),
  //     });
  //     socket.current.on("id_report", (id) => {
  //       setMyID(id);
  //     });
  //     socket.current.on("cam_id", (payload) => {
  //       if (payload.number == 1) {
  //         setCamID1(payload.id);
  //       } else if (payload.number == 2) {
  //         setCamID2(payload.id);
  //       }
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (!connection.current) {
      connection.current = new RTCMultiConnection();

      connection.current.socketURL = process.env.REACT_APP_API_CAM + "/";

      connection.current.socketMessageEvent = "video-broadcast-demo";

      connection.current.session = {
        audio: false,
        video: false,
        oneway: true,
      };

      connection.current.sdpConstraints.mandatory = {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      };

      // first step, ignore default STUN+TURN servers
      connection.current.iceServers = [];

      // second step, set STUN url
      connection.current.iceServers.push({
        urls: process.env.REACT_APP_STUN_SERVER,
      });

      // last step, set TURN url (recommended)
      connection.current.iceServers.push({
        urls: process.env.REACT_APP_TURN_SERVER,
        username: process.env.REACT_APP_TURN_USER,
        credential: process.env.REACT_APP_TURN_PASSWORD,
      });

      //   console.log(connection.current);

      connection.current.videosContainer =
        document.getElementById("videos-container");
      showCam1();

      connection.current.onstream = function (event) {
        var existing = document.getElementById(event.streamid);
        if (existing && existing.parentNode) {
          existing.parentNode.removeChild(existing);
        }

        event.mediaElement.removeAttribute("src");
        event.mediaElement.removeAttribute("srcObject");
        event.mediaElement.muted = true;
        event.mediaElement.volume = 0;

        // var video;
        // if (connection.current.sessionid == "Room1") {
        //   video = document.getElementById("video1");
        // } else if (connection.current.sessionid == "Room2") {
        //   video = document.getElementById("video2");
        // }
        // console.log(video);
        // if (!video) {
        //   return;
        // }

        // var video = document.createElement("video");

        // console.log(event.sessionid);

        // try {
        //   video.setAttributeNode(document.createAttribute("autoplay"));
        //   video.setAttributeNode(document.createAttribute("playsinline"));
        //   video.setAttributeNode(document.createAttribute("muted"));
        // } catch (e) {
        //   video.setAttribute("muted", true);
        //   video.setAttribute("autoplay", true);
        //   video.setAttribute("playsinline", true);
        //   video.setAttribute("id", event.sessionid);
        // }
        // // Mute both audio and video for the new video element
        // video.muted = true;

        // video.srcObject = event.stream;
        // connection.current.videosContainer.appendChild(video);

        if (connection.current.sessionid === `${machineID}1`) {
          setStream1(event.stream);
          showCam2();
        } else {
          setStream2(event.stream);
        }

        // setTimeout(function () {
        //   video.media?.play();
        // }, 5000);
        // console.log(video);
      };

      connection.current.onstreamended = function (event) {
        var mediaElement = document.getElementById(event.streamid);
        if (mediaElement) {
          mediaElement.parentNode.removeChild(mediaElement);

          if (
            event.userid === connection.sessionid &&
            !connection.current.isInitiator
          ) {
            alert(
              "Broadcast is ended. We will reload this page to clear the cache."
            );
            window.location.reload();
          }
        }
      };

      connection.current.onMediaError = function (e) {
        connection.join(connection.sessionid);
      };
    }
    return ()=>{
      connection.current.getAllParticipants().forEach(function(participantId) {
        console.log(participantId)
        connection.current.disconnectWith( participantId );
      });
      connection.current.closeSocket();
      console.log("remove")
    }
  }, []);

  const showCam1 = () => {
    connection.current.sdpConstraints.mandatory = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false,
    };
    connection.current.join(`${machineID}1`, function () {
      console.log(connection.current.sessionid);
    });
  };
  const showCam2 = () => {
    connection.current.sdpConstraints.mandatory = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false,
    };
    connection.current.join(`${machineID}2`, function () {
      console.log(connection.current.sessionid);
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (socket.current) {
        socket.current.emit("remove_user", { machineID: machineID });
      }
    };

    window?.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window?.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [socket.current, machineID]);

  useEffect(() => {
    // Update the video elements when stream1 or stream2 changes
    if (stream1 && camNumber === 0) {
      const videoRef = document.getElementById("video1"); // Get the video element by ID
      if (videoRef) {
        videoRef.srcObject = stream1;
        // Check if the video is ready to show
        videoRef.onloadeddata = () => {
          setIsLoading1(false);
        };
      }
    }
    if (stream2 && camNumber === 1) {
      const videoRef = document.getElementById("video2"); // Get the video element by ID
      if (videoRef) {
        videoRef.srcObject = stream2;
        videoRef.onloadeddata = () => {
          setIsLoading2(false);
        };
      }
    }
  }, [stream1, stream2, camNumber]);

  return (
    <VideoContainer id="videos-container">
      {camNumber === 0 ? (
        stream1 ? (
          <div>
            {isLoading1 ? (
              <div className="BlankContainer">
                <LoadingSpinner />
              </div>
            ) : null}
            <video
              className={`video-machine ${isLoading1 ? "hidden" : ""}`}
              id="video1"
              playsInline
              autoPlay
              muted
            />
          </div>
        ) : (
          <div className="BlankContainer">
            <LoadingSpinner />
          </div>
        )
      ) : null}
      {camNumber === 1 ? (
        stream2 ? (
          <>
            {isLoading2 ? (
              <div className="BlankContainer">
                <LoadingSpinner />
              </div>
            ) : null}
            <video
              className={`video-machine ${isLoading2 ? "hidden" : ""}`}
              id="video2"
              playsInline
              autoPlay
              muted
            />
          </>
        ) : (
          <div className="BlankContainer">
            <LoadingSpinner />
          </div>
        )
      ) : null}
    </VideoContainer>
  );
};

export default Listenner;
