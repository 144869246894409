import "./App.css";
import LoginPage from "./loginPage/loginPage";
import Register from "./registerPage/registerPage";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import MachinesPage from "./machinesPage/machinesPage";
import PlayingPage from "./playingPage/playingPage";
import Navbar from "./components/navbar/navbar";
import ClaimPage from "./claimPage/claimPage";
import PaymentPage from "./paymentPage/paymentPage";
import NotFound from "./notFoundPage/notFoundPage";
import axios from "axios";
import { useEffect, useState } from "react";
import Footer from "./components/footer/footer";
import AdminPage from "./adminPage/adminPage";

import VideoBroadcasting from "./components/videoBroadcast";
import EmailVerify from "./components/emailVerify/emailVerify";
import VendorPage from "./vendor/vendorPage/vendorPage.js";
import VendorMachineInfo from "./vendor/machineInfoPage/machineInfoPage.js";
import { jwtDecode } from "jwt-decode";
import PathListener from "./components/PathListener.js";

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isVendor, setIsVendor] = useState(false);
  const [user, setUser] = useState({});
  let prevPath

  async function fetchData() {
    try {
      setLoading(true)
      const token = localStorage.getItem("token")
      if (token){
        const user = jwtDecode(token);
        if(user.role === "admin"){
          setIsAdmin(true);
        }
        if(user.role === "vendor"){
          setIsVendor(true);
        }
      }
      
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      // Handle login failure
    }
  }

  async function handleGetMe() {
    try {
      setLoading(true)
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/me`,
        {
          withCredentials: true,
        }
      );
      if (response.data) {
        const resUser = response.data.data;
        if (resUser) {
          localStorage.setItem("user", JSON.stringify(resUser));
          setUser(resUser)
        }
        return resUser
      }
    } catch (error) {
      // console.log(error);
      // Handle login failure
    } finally{
      setLoading(false);
    }
  }

  const handlePathChange = async (path) => {
    if(prevPath && prevPath !== path){
      handleGetMe();
      fetchData();
    }
    prevPath = path
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      handleGetMe();
      fetchData();
    }
  }, []);

  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
  const token = localStorage.getItem("token");
  // let user = JSON.parse(localStorage.getItem("user"));
  const isLoggedIn = !!localStorage.getItem("token");
  // useEffect(() => {
  //   setUser(JSON.parse(localStorage.getItem("user")));
  // }, [loading]);

  if (!token) {
    return (
      <div className="App">
        <BrowserRouter>
          <header className="App-header">
            <Navbar
              isLoggedIn={isLoggedIn}
              isAdmin={isAdmin}
              userName={user ? user.name : "NaN"}
              coin={user ? user.coin : "NaN"}
              isTopUpModalOpen={isTopUpModalOpen}
              setIsTopUpModalOpen={setIsTopUpModalOpen}
              />
          </header>
          <body className="App-body">
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/users/:id/verify/:token"
                element={<EmailVerify />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </body>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
  if (loading) {
    // You can render a loading indicator here while waiting for isAdmin value.
    return <div>Loading...</div>;
  }

  if(isVendor){
    return (
      <div className="App">
        <BrowserRouter>
          <header className="App-header">
            <Navbar
              isLoggedIn={isLoggedIn}
              isAdmin={isAdmin}
              userName={user ? user.name : "NaN"}
              coin={user ? user.coin : "NaN"}
              isTopUpModalOpen={isTopUpModalOpen}
              setIsTopUpModalOpen={setIsTopUpModalOpen}
            />
          </header>
            <body className="App-body">
              <Routes>
                <Route path="/" element={<VendorPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/users/:id/verify/:token"
                  element={<EmailVerify />}
                />
                
                <Route path="/machineInfo/:machineID" element={<VendorMachineInfo />} />
                <Route path="/register" element={<Register />} />
                <Route path="/payment/:amount" element={<PaymentPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </body>
            <Footer />
        </BrowserRouter>
      </div>
    );
  }

  if (isAdmin) {
    return (
      <div className="App">
        <BrowserRouter>
          <PathListener onPathChange={handlePathChange} />
          <header className="App-header">
            <Navbar
              isLoggedIn={isLoggedIn}
              isAdmin={isAdmin}
              userName={user ? user.name : "NaN"}
              coin={user ? user.coin : "NaN"}
              isTopUpModalOpen={isTopUpModalOpen}
              setIsTopUpModalOpen={setIsTopUpModalOpen}
            />
          </header>
          <body className="App-body">
            <Routes>
              {/* <Route
                path="/listenner/"
                element={
                  <Listenner
                    machineID={"64ba69b55dc4553eb3684380"}
                    camNumber={0}
                  />
                }
              />
              <Route
                path="/room/:machineID/:camNumber/:sourceNumber"
                element={<VideoBroadcasting />}
              /> */}
              <Route path="/" element={<MachinesPage />} />
              <Route path="/claim" element={<ClaimPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/users/:id/verify/:token"
                element={<EmailVerify />}
              />
              <Route
                path="/play/:prizeId"
                element={
                  <PlayingPage
                    setIsTopUpModalOpen={setIsTopUpModalOpen}
                    setUser={setUser}
                  />
                }
              />
              <Route path="/register" element={<Register />} />
              <Route path="/payment/:amount" element={<PaymentPage />} />
              <Route path="/admin/*" element={<AdminPage />} />
              <Route path="/vendor" element={<VendorPage />} />
              <Route path="/vendor/machineInfo/:machineID" element={<VendorMachineInfo />} />
              <Route
                path="/admin/sender/:machineID/:camNumber/:sourceNumber"
                element={<VideoBroadcasting />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </body>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <header className="App-header">
          <Navbar
            isLoggedIn={isLoggedIn}
            isAdmin={isAdmin}
            userName={user ? user.name : "NaN"}
            coin={user ? user.coin : "NaN"}
            isTopUpModalOpen={isTopUpModalOpen}
            setIsTopUpModalOpen={setIsTopUpModalOpen}
          />
        </header>
        <body className="App-body">
          <Routes>
            <Route path="/" element={<MachinesPage />} />
            <Route path="/claim" element={<ClaimPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/users/:id/verify/:token" element={<EmailVerify />} />
            <Route
              path="/play/:prizeId"
              element={
                <PlayingPage
                  setIsTopUpModalOpen={setIsTopUpModalOpen}
                  setUser={setUser}
                />
              }
            />
            <Route path="/register" element={<Register />} />
            <Route path="/payment/:amount" element={<PaymentPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </body>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
