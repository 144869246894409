import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./slideImage.css";
import banner1 from "../../resorce/banner1.png";
import banner2 from "../../resorce/banner2.png";

const slideImages = [
  {
    url: banner1,
  },
  {
    url: banner2,
  },
];

const Slideshow = () => {
  return (
    <div className="slide-container">
      <Slide>
        {slideImages.map((slideImage, index) => (
            <img
              className="banner"
              key={index}
              src={slideImage.url}
            />
        ))}
      </Slide>
    </div>
  );
};

export default Slideshow;
