import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Swal from "sweetalert2";
import "./crud.css";
import Form from 'react-bootstrap/Form';

export default function List() {
  const [machines, setMachines] = useState([]);

  useEffect(() => {
    fetchMachines();
  }, []);

  const fetchMachines = async () => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/all`,

        {
          withCredentials: true,
        }
      );
      setMachines(response.data.data);
      return response.data;
    } catch (error) {
      console.log(error);
      // Handle login failure
    }
  };

  const deleteMachine = async (id) => {
    const isConfirm = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
    await axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/machine/${id}`,

        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        fetchMachines();
      })
      .catch(({ response: { data } }) => {
        Swal.fire({
          text: data.message,
          icon: "error",
        });
      });
  };

  return (
    
        <div className="row">
          <div>
            <Link className="btn btn-primary mb-2 float-end" to={"machine/create"}>
              Create Machine
            </Link>
          </div>
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-0 text-center">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Belong</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {machines.length > 0 &&
                    machines.map((row, key) => (
                      <tr key={row._id}>
                        <td>
                        {row._id}
                        </td>
                        <td>{row.machineName}</td>
                        <td>{row?.belong?.email}</td>
                        <td>
                          <Link
                            to={`machine/edit/${row._id}`}
                            className="btn btn-success"
                          >
                            Edit
                          </Link>
                          <Button
                            className="btn"
                            variant="danger"
                            onClick={() => deleteMachine(row._id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

  );
}
